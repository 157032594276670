import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { store } from "./store/store";
<link rel="manifest" href="manifest.json"></link>
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Router basename="/">
        <HelmetProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </HelmetProvider>
    </Router>,
);

// added for PWA
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/serviceWorker.js');
//   }
  