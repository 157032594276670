import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
    FaArrowAltCircleLeft,
    FaArrowAltCircleRight,
    FaRegClock,
    FaUsers,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { UserCoinScoreApi } from "../../../store/actions/campaign";
import { settingsData } from "../../../store/reducers/settingsSlice";
import { updateUserDataInfo } from "../../../store/reducers/userSlice";
function TnLLifelines({
  handleFiftFifty,
  handleAudiencePoll,
  handleResetTime,
  handleSkipQuestion,
  handlePrevQuestion,
  t,
  showFiftyFifty,
  audiencepoll,
  showPrevious,
}) {
  const [status, setStatus] = useState({
    fifty_fifty: false,
    audience_poll: false,
    reset_time: false,
    skip_question: false,
  });

  // store data get
  const userData = useSelector((state) => state.User);

  const selectdata = useSelector(settingsData);

  const [lifeLineCount, setlifeLineCount] = useState(0);

  const lifeline_deduct_coin =
    selectdata &&
    selectdata.filter((item) => item.type == "lifeline_deduct_coin");

  const maxlifeLine = 2;
  const [count, setCount] = useState(0)
  const lifeLineClick = (type) => {
    console.log("TnL Lifeline called--->>>>");
    let update;
    if (lifeLineCount >= maxlifeLine) {
      console.log("lifeLineCount Check pass");
      update = {
        fifty_fifty: true,
        audience_poll: true,
        reset_time: true,
        skip_question: false,
      };
      setStatus(update);
      console.log("Status-->", status);
    } else {
      console.log("lifeLineCount Check fail");
      update = {
        fifty_fifty: false,
        audience_poll: false,
        reset_time: false,
        skip_question: false,
      };
      setStatus(update);
      console.log("Status-->", status);
    }
    setlifeLineCount(lifeLineCount + 1);
    console.log("lifeLineCount------>", lifeLineCount);

    if (type === "Fifty Fifty") {
      if (!status.fifty_fifty) {
        if (checkIfUserhasCoins() && handleFiftFifty()) {
          if (deductCoins()) {
            update = { ...status, fifty_fifty: true };
            setStatus(update);
          }
        }
      } else {
        toast.error(t("lifeline_already_used"));
      }
    } else if (type === "Audience Poll") {
      if (!status.audience_poll) {
        if (deductCoins()) {
          update = { ...status, audience_poll: true };
          handleAudiencePoll();
          setStatus(update);
        }
      } else {
        toast.error(t("lifeline_already_used"));
      }
    } else if (type === "Reset Time") {
      
      if (count < maxlifeLine) {
        handleResetTime();
        setCount(count+1)
        console.log("Count--->", count)
      }
      else{
        toast.error(t("You can reset the time two times, max!"));
      }
      
      // if (!status.reset_time) {
      //     if (deductCoins()) {

      //         update = { ...status, reset_time: true };
      //         handleResetTime();
      //         setStatus(update);

      //     }
      // } else {
      //     toast.error(t("lifeline_already_used"));
      // }
    } else if (type === "Skip Question") {
      if (!status.skip_question) {
        if (deductCoins()) {
          update = { ...status, skip_question: true };
          handleSkipQuestion();
          setCount(0)

          //update = { fifty_fifty: false, audience_poll: false, reset_time: false, skip_question: false  };
          //setStatus(update);
        }
      } else {
        toast.error(t("lifeline_already_used"));
      }
    } else if (type === "Prev Question") {
      if (1) {
        handlePrevQuestion();
      } else {
        toast.error(t("lifeline_already_used"));
      }
    }
  };

  const deductCoins = () => {
    //added by sujit to bypass coin deduction
    return true;
    if (checkIfUserhasCoins()) {
      let coins =
        "-" +
        (Number(lifeline_deduct_coin[0].message)
          ? Number(lifeline_deduct_coin[0].message)
          : 0);
      UserCoinScoreApi(
        coins,
        null,
        null,
        "Used Hint Lifeline",
        "1",
        (response) => {
          updateUserDataInfo(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
      return true;
    } else {
      return false;
    }
  };

  const checkIfUserhasCoins = () => {
    //added by sujit to bypass coin deduction
    return true;
    if (
      userData.data.coins <
      (Number(lifeline_deduct_coin[0].message)
        ? Number(lifeline_deduct_coin[0].message)
        : 0)
    ) {
      toast.error(t("You Don't have enough coins"));
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="dashoptions row">
      {showPrevious ? (
        <div
          className="skip__questions col-3 col-sm-3 col-md-2 custom-life-btn"
          data-tooltip-id="lifeline-my-tooltip"
          data-tooltip-content={t("Previous Question")}
        >
          <button
            className={`btn btn-primary p-2 ${
              status.skip_question && "bg-secondary"
            }`}
            onClick={() => lifeLineClick("Prev Question")}
          >
            <FaArrowAltCircleLeft />
          </button>
        </div>
      ) : (
        ""
      )}
      {showFiftyFifty ? (
        <div
          className="fifty__fifty col-3 col-sm-3 col-md-2 custom-life-btn"
          data-tooltip-id="lifeline-my-tooltip"
          data-tooltip-content={t("Fifty Fifty")}
        >
          <button
            className={`btn btn-primary fiftybtn py-2 ${
              status.fifty_fifty && "bg-secondary"
            }`}
            onClick={() => lifeLineClick("Fifty Fifty")}
          >
            50/50
          </button>
        </div>
      ) : (
        ""
      )}

      {audiencepoll ? (
        <div
          className="audience__poll col-3 col-sm-3 col-md-2 custom-life-btn"
          data-tooltip-id="lifeline-my-tooltip"
          data-tooltip-content={t("Audience Poll")}
        >
          <button
            className={`btn btn-primary p-2 ${
              status.audience_poll && "bg-secondary"
            }`}
            onClick={() => lifeLineClick("Audience Poll")}
          >
            <FaUsers />
          </button>
        </div>
      ) : (
        ""
      )}
      <div
        className="resettimer col-3 col-sm-3 col-md-2 custom-life-btn"
        data-tooltip-id="lifeline-my-tooltip"
        data-tooltip-content={t("Reset Time")}
      >
        <button
          className={`btn btn-primary p-2 ${
            status.reset_time && "bg-secondary"
          }`}
          onClick={() => lifeLineClick("Reset Time")}
        >
          <FaRegClock />
        </button>
      </div>
      <div
        className="skip__questions col-3 col-sm-3 col-md-2 custom-life-btn"
        data-tooltip-id="lifeline-my-tooltip"
        data-tooltip-content={t("Skip Question")}
      >
        <button
          className={`btn btn-primary p-2 ${
            status.skip_question && "bg-secondary"
          }`}
          onClick={() => lifeLineClick("Skip Question")}
        >
          <FaArrowAltCircleRight />
        </button>
      </div>
      <Tooltip id="lifeline-my-tooltip" />
    </div>
  );
}
TnLLifelines.propTypes = {
  handleFiftFifty: PropTypes.func.isRequired,
  handleAudiencePoll: PropTypes.func.isRequired,
  handleResetTime: PropTypes.func.isRequired,
  handleSkipQuestion: PropTypes.func.isRequired,
  handlePrevQuestion: PropTypes.func.isRequired,
};
export default withTranslation()(TnLLifelines);
